/* global */

// sync font faces with uikit config
$yr-font-family:              "PT Sans", sans-serif;
$yr-heading-font-family:      $yr-font-family;
$yr-nav-font-family:          $yr-font-family;

$link-color:                  #07d; // from uikit

$username-first-letter-color: #f11;

/* topic */
$topic-actions-delete-color:  #da4242;
$topic-footer-icon-color:     #a2b6cd;

$favorite-inactive: none;
@if $theme == light {
  $favorite-inactive: #444 !global; // uikit button
} @else {
  $favorite-inactive: #999 !global;
}
$favorite-active:             #fed038;

/* uikit breakpoints */
$breakpoint-small:            480px;
$breakpoint-medium:           768px;
$breakpoint-large:            960px;
$breakpoint-xlarge:           1220px;

$breakpoint-mini-max:         ($breakpoint-small  - 1px);
$breakpoint-small-max:        ($breakpoint-medium - 1px);
$breakpoint-medium-max:       ($breakpoint-large  - 1px);
$breakpoint-large-max:        ($breakpoint-xlarge - 1px);
