// Temporary fixes for old style after uikit introduction

@import "styles/synio/config";

// container

#container {
    max-width: 1152px;
}

// lists

ul.latest-list,
ul.nav,
ul.topic-info,
ul.topic-tags,
ul.footer-list,
ul.dropdown-user-menu,
ul.profile-dotted-list,
ul.profile-contact-list,
ul.user-list-avatar,
ul.tag-cloud,
ul.block-blog-list,
ul.topic-actions,
ul.comment-info,
ul.comment-vote,
ul.search-abc,
ul.activity-settings-filter,
ul.stream-settings-friends,
ul.user-list-mini,
ul.stream-list,
ul.block-stat-data,
ul.pagination-list,
ul.friend-list,
ul.actions {
    padding-left: 0;
    margin-bottom: 0;
    list-style: outside none none;
}

ul.dropdown-menu-create {
    margin-top: 0;
    margin-bottom: 0;
    list-style: outside none none;
}

ul.footer-list {
    margin-top: 0;
}

.latest-list p,
.profile-info-about p,
.profile-info-about h3 {
    margin: 0;
}

// login modal
.modal-login {
    z-index: 1000;
}

.topic > .topic-header > .topic-actions > li.edit > i {
    color: $link-color;
}

.topic > .topic-header > .topic-actions > li.delete > i {
    color: $topic-actions-delete-color;
}
