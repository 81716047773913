// Fixes for uikit styles

// do not color italic
em, .uk-light em {
    color: inherit;
}

// do not change blockquote size
blockquote {
    font-size: inherit;
}

// do not use skip ink in underlines in Chrome 64+
a {
    text-decoration-skip-ink: none;
}

.uk-light .uk-button-default:disabled {
    color: #999;
}
